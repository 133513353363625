<template>
  <div class="main">
    <div class="title">新建检测</div>
    <el-form :model="ruleForm"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm">
      <el-form-item label="检测报告："
                    style="width: 20%;"
                    prop="detection_name">
        <el-input placeholder="请输入"
                  v-model="self.detection_name"></el-input>
      </el-form-item>
      <el-form-item label="科目："
                    style="width: 20%;"
                    prop="subject_ids">
        <el-select style="width:100%"
                   v-model="self.subject_ids"
                   clearable
                   multiple
                   placeholder="选择科目">
          <el-checkbox v-model="checked"
                       style="margin-left: 6px;"
                       @change='selectAll'>全选</el-checkbox>
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
            <el-checkbox @change='boxChange(item)'
                         v-model="item.checked">{{ item.subject_name }}</el-checkbox>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="goback()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAllSubject } from '@/api/getSubjectList.js'
import { addTestReport } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      ruleForm: {
        detection_name: '',
        subject_ids: '',
        user_id: ''
      },
      self: {

      },
      rules: {
        detection_name: [
          { required: true, message: '请输入检测报告', trigger: 'blur' },
        ],
        subject_ids: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
      },
      subjectList: [],
      self: {},
      checked: false,

    };
  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '114px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'

  },
  async created () {
    this.$http({
      url: '/api/v1/weak/common_subjects',
      methods: 'get',
      params: {
        user_id: this.$route.query.user_id || window.localStorage.getItem('user_id'),
      }
    }).then(res => {
      this.subjectList = res.data.list
    })

  },
  watch: {
    'self': {
      handler (newVal) {
        // 当 self.subject_ids 的值发生改变时，执行相关操作

        this.selectChange(newVal.subject_ids);
      },
      deep: true
    }
  },
  methods: {
    selectAll () {
      this.self.subject_ids = []
      if (this.checked) {
        this.subjectList.map((item) => {
          this.self.subject_ids.push(item.subject_id)
        })
      } else {
        this.self.subject_ids = []
      }
    },
    boxChange (item) {

      if (item.checked == false) {
        item.checked = true
        this.self.subject_ids.push(item.subject_id)

      } else {
        item.checked = false

        this.self.subject_ids.splice(this.self.subject_ids.length - 1, 1)
      }
      // 在这里处理复选框变化的逻辑
    },
    selectChange (value) {

      this.subjectList.forEach((item) => {
        if (value.includes(item.subject_id)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    },
    submitForm (formName) {
      this.self.user_id = Number(this.$route.query.user_id)
      this.self.subject_ids = this.self.subject_ids.join(',')


      this.$refs[formName].validate((valid) => {
        let params = this.self

        if (valid) {
          this.$http({
            url: '/api/v1/tang/create_detection',
            method: 'post',
             data:params
          }).then(res => {
            this.$notify({
              title: '提示',
              message: '提交成功!',
              type: 'success'
            });
            this.resetForm(formName)
            this.$router.push(
              {
                path: '/dataAnalysis/report',
                // query: {
                //   user_id: row.id,
                //   user_paper_id: row.user_paper_id
                // }
              })
          })

        } else {
          this.$notify({
            title: '提示',
            message: '提交失败!',
            type: 'error'
          });
          return false;
        }
      });
    },
    // async postData (params) {
    //   const { data } = await addTestReport(params)
    // },
    resetForm (formName) {
      this.$refs[formName].resetFields();

    },
    goback () {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  font-family: Regular;
  // padding: 24px 24px 0 24px;
  padding: 12px;
  background: white;
  .title {
    top: 142px;
    left: 256px;
    width: 80px;
    height: 30px;
    // background: rgba(17, 17, 17, 1);
    margin: 8px 0px 30px 12px;
    color: rgba(17, 17, 17, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}
::v-deep .el-button {
  padding: 0;
  width: 60px;
  height: 32px;
  margin-top: 16px;
}

::v-deep .el-form-item__content {
  margin-bottom: 6px;
}
::v-deep .el-form-item__label {
  font-size: 14px !important;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
.el-select-dropdown__item.selected::after {
  display: none !important;
}

.pedding {
  color: green;
}
</style>